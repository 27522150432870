var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "pt-lg-0 pt-5"
  }, [_c('h2', {
    staticClass: "darkGrey--text bold-text"
  }, [_vm._v("Welcome to TestApp.io")]), _c('div', {
    staticClass: "bold-text darkGrey--text font-size-h4"
  }, [_vm._v(" New here? "), _c('router-link', {
    staticClass: "bold-text primary--text text-decoration-underline mx-1",
    attrs: {
      "to": {
        name: 'signup',
        params: {
          email: _vm.email
        }
      }
    }
  }, [_vm._v("Create an Account")])], 1)]), _vm.getAuthMessage.message ? _c('vue-inline-alert', {
    staticClass: "mt-4",
    attrs: {
      "message-type": _vm.getAuthMessage.type,
      "message": _vm.getAuthMessage.message
    }
  }) : _vm._e(), _vm.tab === 1 ? _c('v-card', {
    staticClass: "transparent mt-15",
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "font-size-h3"
  }, [_vm._v("Let's check your work email.")]), _c('div', {
    staticClass: "mt-7",
    on: {
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.checkEmail.apply(null, arguments);
      }
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|email',
      expression: "'required|email'"
    }],
    attrs: {
      "filled": "",
      "id": "checkEmail",
      "autofocus": "",
      "placeholder": "Enter your work email address"
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }, 'v-text-field', _vm.veeValidate('Work email', 'Work email'), false)), _c('div', {
    staticClass: "form-group d-flex flex-wrap justify-center align-center"
  }, [_c('v-btn', {
    staticClass: "main primary",
    attrs: {
      "loading": _vm.busy,
      "id": "nextButton",
      "disabled": _vm.busy || _vm.errors.any()
    },
    on: {
      "click": _vm.checkEmail
    }
  }, [_vm._v(" Next ")])], 1), _c('div', [_c('social-auth', {
    attrs: {
      "button-text": "Sign in with",
      "default-signin": ""
    },
    on: {
      "loading": function loading($event) {
        _vm.busy = $event;
      }
    }
  })], 1)], 1)]) : _vm._e(), _vm.tab === 2 ? _c('v-card', {
    staticClass: "transparent mt-15",
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, [_c('signin-form', {
    attrs: {
      "default-signin": "",
      "email": _vm.email
    },
    on: {
      "change-email": function changeEmail($event) {
        _vm.tab = 1;
      },
      "error": _vm.setErrorMessage
    }
  })], 1) : _vm._e(), _c('vue-snack-bar')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }